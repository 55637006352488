import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom"; // Adjust import as necessary
import LanguageSwitcher from './LanguageSwitcher';
import './Navbar.css'

function Navbar() {
  const { t } = useTranslation();

  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const dropdownItemStyle = {paddingTop: 8, color: 'black', backgroundColor: 'white'}

  return (
    <nav className="navbar navbar-light bg-custom fixed-top ps-1" style={{backgroundColor: 'white', border: 2, borderColor: 'black', borderStyle: 1}}>
      <button className="navbar-toggler" type="button" onClick={handleNavCollapse}>
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`}>
        <Link className="dropdown-item ps-3 dropdownItem" style={dropdownItemStyle} to="/">{t('navigation.home')}</Link>
        <Link className="dropdown-item ps-3 dropdownItem" style={dropdownItemStyle} to="/terms-and-conditions">{t('navigation.tAndC')}</Link>
        <ul className="navbar-nav mr-auto">
          <li className={`nav-item dropdown ${isDropdownOpen ? 'show' : ''}`}>
            <a className="nav-link dropdown-toggle dropdownItem ps-3 " style={dropdownItemStyle} href="#" id="navbarDropdown" role="button" onClick={toggleDropdown} aria-expanded={isDropdownOpen}>
              {t('navigation.language')}
            </a>
            {isDropdownOpen && <LanguageSwitcher />}
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
