import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

function Footer() {
  const { t } = useTranslation();

  return (
    <footer>
      <p style={{paddingTop: 12}}>{t('footer.madeBy')}</p>
      <Link to="/terms-and-conditions" style={{color: "white", paddingRight: 12}}>{t('footer.tAndC')}</Link>
      <Link to="/privacy-policy" style={{color: "white"}}>{t('footer.privacyPolicy')}</Link>
    </footer>
  )
}

export default Footer;
