import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

import translationEN from './locales/en/translation.json';
import translationPL from './locales/pl/translation.json';
import translationSR from './locales/sr/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  pl: {
    translation: translationPL,
  },
  sr: {
    translation: translationSR,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "en",
    detection: {
      order: ['localStorage', 'navigator'], // First try localStorage, then the browser language
      caches: ['localStorage'], // Cache the language in localStorage
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
