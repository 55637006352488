export const questions = [
  "What is AI?",
  "Why did people come up with AI?",
  "What types of AI are there?",
  "Why are self-driving cars an example of Narrow AI?",
  "How does a machine \"learn\"?",
  "Is AI the same as Deep Learning?",
  "What does Deep Learning do?",
  "Is AI the same as Machine Learning?",
  "What is an algorithm?"
]

export const answers = [
  "AI is a set of techniques for writing computer programs that make intelligent decisions.",
  "AI is intended to solve problems where it's impractical to tell a computer what to do, step-by-step.",
  "Narrow AI\n\nGeneral AI",
  "Self-driving cars can drive really well, but they can't solve any other type of task.",
  "When a computer program \"learns\", it remembers the best solution based on the data it was given.",
  "No, Deep Learning is a technique used within AI.",
  "Deep Learning studies how to teach machines efficiently on very large sets of data.",
  "No, ML is just one of the research fields of AI.",
  "It's a series of steps a human or a machine can take to solve a problem."
]