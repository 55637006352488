import React from 'react';
import {useTranslation} from "react-i18next";

const PrivacyPage = () => {
  const {t} = useTranslation();
  const appUrl = "www.kgtree.net"

  const containerStyle = {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    lineHeight: '1.6',
    paddingTop: '100px',
    paddingBottom: '100px'
  };

  return (
    <div style={containerStyle}>
      <h1 style={{paddingBottom: 20}}>{t('privacy.title')}</h1>
      <p><strong>{t('privacy.lastUpdated')}</strong>: {t('privacy.lastUpdatedDate')}</p>
      <p>{t('privacy.intro', {appName: t('appName')})}</p>

      <h2>{t('privacy.dataCollection.title')}</h2>
      <p>{t('privacy.dataCollection.weDoNotCollect')}</p>
      <ul>
        <li>{t('privacy.dataCollection.anonymousId')}</li>
        <li>{t('privacy.dataCollection.transactionHistory')}</li>
      </ul>
      <p>{t('privacy.dataCollection.sentToRevenueCat')}</p>

      <h2>{t('privacy.dataUse.title')}</h2>
      <p><strong>{t('privacy.dataUse.thirdParty')}</strong>{t('privacy.dataUse.thirdPartyContent')}</p>

      <h2>{t('privacy.userRights.title')}</h2>
      <p>{t('privacy.userRights.usersHaveRightTo')}</p>
      <ul>
        <p><strong>{t('privacy.userRights.accessInformation')}</strong>{t('privacy.userRights.accessInformationContent')}</p>
        <p><strong>{t('privacy.userRights.correctInformation')}</strong>{t('privacy.userRights.correctInformationContent')}</p>
        <p><strong>{t('privacy.userRights.dataDeletion')}</strong>{t('privacy.userRights.dataDeletionContent')}</p>
      </ul>
      <p>{t('privacy.userRights.canExercise')}: <a href="https://www.revenuecat.com/contact">https://www.revenuecat.com/contact</a>.</p>

      <h2>{t('privacy.cookiesAndTracking.title')}</h2>
      <p>{t('privacy.cookiesAndTracking.weDoNotUse')}</p>

      <h2>{t('privacy.dataRetentionAndSecurity.title')}</h2>
      <p><strong>{t('privacy.dataRetentionAndSecurity.retention')}</strong>{t('privacy.dataRetentionAndSecurity.retentionContent')}</p>
      <p><strong>{t('privacy.dataRetentionAndSecurity.securityMeasures')}</strong>{t('privacy.dataRetentionAndSecurity.securityMeasuresContent')}</p>

      <h2>{t('privacy.policyUpdates.title')}</h2>
      <p>{t('privacy.policyUpdates.content')}</p>

      <h2>{t('privacy.contactInformation.title')}</h2>
      <p>{t('privacy.contactInformation.content')}<a href="https://forms.gle/wU9Xh9Fun8zYug5k8">link</a>.</p>
    </div>
  );
};

export default PrivacyPage;