import React, {useState} from 'react';
import './Card.css'; // Import the CSS file

const Card = ({question, answer, isShowingAnswer}) => {
  return (
    <div className="cardContainer" style={{display: "flex", alignItems: "center"}}>
      <p style={{
        height: 200,
        display: "flex",
        alignItems: "center",
        color: "black",
        whiteSpace: 'pre-line',
        fontFamily: 'American Typewriter Regular'
      }}>{isShowingAnswer ? answer : question}</p>
    </div>
  );
};

export default Card;
