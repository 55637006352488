import React from 'react';
import {useTranslation} from 'react-i18next';
import './LanguageSwitcher.css'

function LanguageSwitcher({ toggleDropdown }) {
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    if (toggleDropdown) toggleDropdown(); // Close the dropdown menu
  };

  const languageItemStyle = {backgroundColor: 'white'}

  return (
    <div style={{backgroundColor: 'white'}}>
      <a className="dropdown-item languageItem ps-4" style={languageItemStyle} href="#" onClick={() => changeLanguage('en')}>{t('language.english')}</a>
      <a className="dropdown-item languageItem ps-4" style={languageItemStyle} href="#" onClick={() => changeLanguage('pl')}>{t('language.polish')}</a>
      <a className="dropdown-item languageItem ps-4" style={languageItemStyle} href="#" onClick={() => changeLanguage('sr')}>{t('language.serbian')}</a>
    </div>
  );
}

export default LanguageSwitcher;
