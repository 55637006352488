import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import './App.css';
import './Button.css';
import './Card.css'
import Footer from "./Footer";
import HomePage from "./HomePage";
import './i18n';
import Navbar from "./Navbar";
import PrivacyPage from "./PrivacyPage";
import TandCPage from "./TandCPage";


function App() {
  return (
    <BrowserRouter>
      <Navbar/>

      <Routes>
        <Route path="/" element={<HomePage/>}/>
        <Route path="/terms-and-conditions" element={<TandCPage/>}/>
        <Route path="/privacy-policy" element={<PrivacyPage/>}/>
      </Routes>

      <Footer/>
    </BrowserRouter>
  );
}

export default App;
