import {faArrowRight, faArrowRotateLeft} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import Card from "./Card";
import {answers, questions} from "./const";

function HomePage() {
  const { t } = useTranslation();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isShowingAnswer, setIsShowingAnswer] = useState(false);

  return (
    <div className="App">
      <header className="App-header">
        <h1>{t('appName')}</h1>
        <div>
          <Card question={questions[currentIndex] || t('homePage.wellDone')} answer={answers[currentIndex]}
                isShowingAnswer={isShowingAnswer}
                setIsShowingAnswer={setIsShowingAnswer}/>
        </div>
        <div>
          {currentIndex < questions.length ?
            <button variant="light" className="answer-button"
                    onClick={() => setIsShowingAnswer(!isShowingAnswer)}>{isShowingAnswer ? t('homePage.hideAnswer') : t('homePage.showAnswer')}</button> :
            <div style={{height: "50px"}}></div>}
        </div>
        <div style={{paddingTop: "30px"}}>
          <p>{currentIndex + 1} / {questions.length}</p>
          {currentIndex + 1 < questions.length ?
            <button variant="light" className="custom-button" onClick={() => {
              setCurrentIndex(currentIndex + 1)
              setIsShowingAnswer(false)
            }}><FontAwesomeIcon icon={faArrowRight} size={"sm"}/></button> :
            <button variant="light" className="custom-button" onClick={() => {
              setCurrentIndex(0)
              setIsShowingAnswer(false)
            }}><FontAwesomeIcon icon={faArrowRotateLeft} size={"sm"}/></button>
          }
        </div>
        <br/>
        <p>({t('homePage.comingToAppStore')})</p>
      </header>
    </div>
  );
}

export default HomePage;
