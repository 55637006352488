import React from 'react';
import {useTranslation} from "react-i18next";

const TandCPage = () => {
  const {t} = useTranslation();
  const appUrl = "www.kgtree.net"

  const containerStyle = {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    lineHeight: '1.6',
    paddingTop: '100px',
    paddingBottom: '100px'
  };

  return (
    <div style={containerStyle}>
      <h1 style={{paddingBottom: 20}}>{t('tAndC.termsAndConditionsFor')} {t('appName')}</h1>
      <p><strong>{t('tAndC.lastUpdated')}</strong>: {t('tAndC.lastUpdatedDate')}</p>
      <p>{t('tAndC.welcomeText1', {appName: t('appName')})}</p>
      <p>{t('tAndC.welcomeText2', {appName: t('appName'), companyName: t('tAndC.companyName'), appUrl})}</p>
      <p>{t('tAndC.acceptTermsPrompt', {appName: t('appName')})}</p>

      <h2>{t('tAndC.introductionTitle')}</h2>
      <p>{t('tAndC.introduction')}</p>

      <h2>{t('tAndC.userAccessAndUseTitle')}</h2>
      <p>{t('tAndC.userAccessAndUse.accessAndUse')}</p>
      <p>{t('tAndC.userAccessAndUse.minimumAgeRequirement')}</p>

      <h2>{t('tAndC.intellectualPropertyRightsAndUserContent.title')}</h2>
      <p>{t('tAndC.intellectualPropertyRightsAndUserContent.userContentOwnership', {appName: t('appName')})}</p>
      <p>{t('tAndC.intellectualPropertyRightsAndUserContent.noStorageOnServers', {
        companyName: t('tAndC.companyName'),
        companyCountry: t('tAndC.companyCountry')
      })}</p>
      <p>{t('tAndC.intellectualPropertyRightsAndUserContent.sharingOfFlashcards', {companyName: t('tAndC.companyName')})}</p>

      <h2>{t('tAndC.dataCollection.title')}</h2>
      <p>{t('tAndC.dataCollection.ourPolicy')}</p>
      <p>{t('tAndC.dataCollection.revenueCat')} <a
        href={t('tAndC.dataCollection.revenueCatPrivacyUrl')}>{t('tAndC.dataCollection.revenueCatPrivacyUrl')}</a></p>

      <h2>{t('tAndC.disclaimersAndLimitationOfLiabilityTitle')}</h2>
      <p>{t('tAndC.disclaimersAndLimitationOfLiability.serviceAsIs')}</p>
      <p>{t('tAndC.disclaimersAndLimitationOfLiability.limitationOfLiability', {companyName: t('tAndC.companyName')})}</p>

      <h2>{t('tAndC.amendmentsToTermsTitle')}</h2>
      <p>{t('tAndC.amendmentsToTerms')}</p>

      <h2>{t('tAndC.governingLawTitle')}</h2>
      <p>{t('tAndC.governingLaw', {companyCountry: t('tAndC.companyCountry')})}</p>

      <h2>{t('tAndC.contactInformationTitle')}</h2>
      <p>{t('tAndC.contactInformation')}<a href="https://forms.gle/wU9Xh9Fun8zYug5k8">link</a></p>
    </div>
  );
};

export default TandCPage;